import { PageProps } from 'gatsby'
import React, { createContext, useContext } from 'react'

const LocationContext = createContext<{ location: PageProps['location'] }>({
  location: null
})

export const useLocation = () => useContext(LocationContext)

export const LocationProvider = ({ children, location }) => (
  <LocationContext.Provider value={{ location }}>
    {children}
  </LocationContext.Provider>
)
