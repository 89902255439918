import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: false, // デフォルトの言語を設定する
    returnEmptyString: false, // 空文字での定義を許可
    resources: {
      // 自分の用意した翻訳ファイルを任意の場所から読み込む
      en: {
        translation: require('./locales/en.json')
      },
      th: {
        translation: require('./locales/th.json')
      },
      ja: {
        translation: require('./locales/ja.json')
      }
    },
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span']
    }
  })

export default i18n
