import React, { Fragment } from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n/config'
import { LocationProvider } from '@/src/contexts/LocationContext'

export const rootWrapper = ({ element, props }) => (
  <Fragment>
    <LocationProvider location={props.location}>
      <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
    </LocationProvider>
  </Fragment>
)
